<template>
  <div class="page">
    <base-left-nav-bar :activeIndex="activeIndex"></base-left-nav-bar>
    <div class="main">
      <base-top-nav-bar></base-top-nav-bar>
      <div class="mian-wrapper">
        <base-page-header>
          <span slot="r"
            ><slot><a href="/userList">用户管理 — </a></slot></span
          >
          <span slot="m"
            ><slot><a href="/userList">用户列表 </a></slot></span
          >
          <span slot="l"><slot>> 创建用户 </slot></span>
        </base-page-header>
        <div class="main-manager">
          <el-form
            :model="userForm"
            :rules="rules"
            ref="userForm"
            label-width="150px"
            class="userForm"
          >
            <el-form-item label="姓名：" prop="name">
              <el-input v-model="userForm.name" placeholder="请输入"></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
              <el-select v-model="userForm.sex" placeholder="请选择">
                <el-option label="男" :value="0"></el-option>
                <el-option label="女" :value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="手机号：" prop="phone">
              <el-input
                v-model="userForm.phone"
                placeholder="请输入"
                auto-complete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="密码：" prop="repassword">
              <el-input
                v-model="userForm.repassword"
                placeholder="请输入"
                show-password
                auto-complete="off"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="工号：">
              <el-input
                v-model="userForm.workid"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司：">
              <el-select v-model="userForm.companyid" placeholder="请选择">
                <el-option
                  v-for="item in companyOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button class="reset" @click="resetForm('userForm')"
                >重置</el-button
              >
              <el-button
                class="submit"
                type="primary"
                @click="submitForm('userForm')"
                >确认</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseLeftNavBar from 'components/base-nav-bar/base-left-nav-bar.vue'
import BaseTopNavBar from 'components/base-nav-bar/base-top-nav-bar.vue'
import BasePageHeader from '../../components/base-page-header/base-page-header.vue'

import UserJS from 'network/user/user.js'

export default {
  components: {
    BaseLeftNavBar,
    BaseTopNavBar,
    BasePageHeader
  },
  data() {
    return {
      activeIndex: 0,
      userForm: {
        name: '',
        sex: '',
        phone: '',
        workid: '',
        password: '',
        repassword: '',
        companyid: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名名称', trigger: 'blur' },
          { min: 2, max: 5, message: '长度在 2 到 5 个字符', trigger: 'blur' }
        ],
        sex: [{ required: true, message: '请选择性别', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '请输入正确的手机号', trigger: 'blur' }
        ],
        repassword: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern: /(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{5,16}$/,
            message: '请设置5-16位，包含字母、数字或符号的密码',
            trigger: 'blur'
          }
        ],
        companyid: [
          { required: true, message: '请选择公司', trigger: 'change' }
        ]
      },
      companyOptions: []
    }
  },
  watch: {},
  methods: {
    /**
     * 事件监听
     */
    // 创建新用户
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.userCreate()
        }
      })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    /**
     * 网络请求
     */
    userCreate() {
      this.userForm.session_id = this.$store.state.loginInfo.session_id
      this.userForm.password = this.$md5(this.userForm.repassword)
      this.userForm.repassword = ''
      UserJS.userCreate(this.userForm).then((res) => {
        if (res.code == 0) {
          this.$message.success(res.msg)
          setTimeout(() => {
            this.resetForm('userForm')
            this.$router.replace('/userList')
          }, 500)
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    },
    // 获取公司列表
    getCompanyList() {
      const param = {
        session_id: this.$store.state.loginInfo.session_id,
        count: 99,
        page: 0
      }
      UserJS.companyList(param).then((res) => {
        if (res.code == 0) {
          const arr = []
          for (const i of res.company_list) {
            const obj = { value: '', label: '' }
            obj.value = i.companyid
            obj.label = i.name
            arr.push(obj)
          }
          this.companyOptions = arr
        } else {
          this.$message.closeAll()
          this.$message({
            showClose: true,
            duration: 1500,
            message: res.msg,
            type: 'warning'
          })
        }
      })
    }
  },
  mounted() {
    this.getCompanyList()
  },
  created() {
    this.$store.commit(
      'setLogin',
      JSON.parse(sessionStorage.getItem('loginInfo'))
    )
  }
}
</script>

<style lang="less" scoped>
.page {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  min-width: 1300px;
  min-height: 100vh;
  // min-height: 1080px;
}
.main {
  width: 1698px;
  height: auto;
  background: #ebecee;
}
.mian-wrapper {
  padding: 28px; // 边距
  width: 1698px;
  height: 879px;
  .main-manager {
    width: 1642px;
    height: 82.5vh;
    background: #fff;
    .userForm {
      padding: 85px 0 0 36px;
      width: auto;
      height: auto;
      .reset {
        margin-left: 90px;
        border-radius: 0;
      }
      .submit {
        margin-left: 20px;
        border-radius: 0;
      }
    }
  }

  ::v-deep {
    .el-input__inner {
      border-radius: 0px;
    }
    .el-form-item:not(:last-child) {
      margin-bottom: 28px;
      border-radius: 0px;
      .el-form-item__label {
        font-size: 18px;
        font-family: Source Han Sans CN;
      }
      .el-input {
        width: 332px;
        height: 42px;
      }
    }
  }
}
</style>
